@keyframes ldio-6hskwgs33f {
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
}

.ldio-6hskwgs33f div { box-sizing: border-box!important }

.ldio-6hskwgs33f > div {
  position: absolute;
  width: 144px;
  height: 144px;
  top: 28px;
  left: 28px;
  border-radius: 50%;
  border: 16px solid #000;
  border-color: #5F8070 transparent #5F8070 transparent;
  animation: ldio-6hskwgs33f 1s linear infinite;
}

.ldio-6hskwgs33f > div:nth-child(2) { border-color: transparent }

.ldio-6hskwgs33f > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}

.ldio-6hskwgs33f > div:nth-child(2) div:before, .ldio-6hskwgs33f > div:nth-child(2) div:after { 
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -16px;
  left: 48px;
  background: #5F8070;
  border-radius: 50%;
  box-shadow: 0 128px 0 0 #5F8070;
}

.ldio-6hskwgs33f > div:nth-child(2) div:after { 
  left: -16px;
  top: 48px;
  box-shadow: 128px 0 0 0 #5F8070;
}

.loadingio-spinner-dual-ring-ejwvlkkwohj {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}

.ldio-6hskwgs33f {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.ldio-6hskwgs33f div { box-sizing: content-box; }

h2 {
	font-family: 'SEGA LOGO FONT';
	font-weight: 200;
	color: #5F8070;
}

